
    <login-home-navigation></login-home-navigation>


  
  

    	<!--Section-->
		<section class="sptb innerpage-margin" style="background-color: white;" >
			<div class="container" style="margin-top:3%;">
                <div class="row">           
                    
                    <div class="col-lg-12 col-sm-12 col-md-12">
                        <div class="card pt-0">
                            <img src="assets/images/media/career.webp" height="100%" width="100%">
                           
                        </div>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-md-12">
                        <div class="mb-0" style="background-color: white">
                            <div class="card-header">
                                <h2 class="mb-0 mt-2">Grow with us!!</h2>
                            </div>
                           
                            <div class="card-body"  >
                              
                                <p>Indisourcing (ISSPL) is the fastest growing company focused on <a href="blog/Custom-Manufacturing-The-USP-of-distinctive-brands"> custom manufacturing</a> services. Our mission is to provide the best B2B platform for Indian manufacturing companies to connect with domestic and international buyers. We are looking for candidates who are willing to work passionately in a fast-paced environment and are ready to enhance their skills by learning something new. Being a part of Indisourcing Solutions will allow you to unleash your potential and carve your own career.</p>
                            </div>
                       
                        </div>
                    </div>
                </div>
				<div class="row">
					<div class="col-sm-12 col-lg-12 col-md-12" >
						<!--Itemsd lists-->
						<div class="mb-lg-0" style="background-color:#f4f0ea;" >
							<!-- <div class="card"> -->
								<div class="item2-gl">
																

								<ng-container >
									<div class="tab-content Marketplace"  style="background-color: white;" >
										<div class="tab-pane active" id="tab-11">
										    <div class="d-flex m-b-20">
										        <div>
                                                    <div class="card-header">
                                                        <h3 class="card-search" style="margin-left:5px"><b> Open Positions </b></h3>                                                  
                                                    </div>
                                            
                                                <div class="card-body">
                                                    <div class="panel-group1" id="accordion2">
                                                        <div class="panel panel-default mb-4 border">
                                                            <div class="panel-heading1 vendor-profile-panel">
                                                                <h4 class="panel-title1">
                                                        <a class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion2" href="#collapseFive" aria-expanded="false">
                                                            Inside Sales Executive: 1-3 Years , Permanent Position, Location: Pune</a>
                                                                </h4>
                                                    </div>
                                                <div id="collapseFive" class="panel-collapse collapse" role="tabpanel" aria-expanded="false">
                                                    <div class="panel-body card-bg">
                                                    
                                                    <div class="row">
                                                    <div class="table-responsive">
                                                        <div class="tag-search" style="margin: 2%;"><b> Job Objective:</b> The objective of this role is 
                                                            to sell paid services to new and existing members of the platform via an online product demonstration.
                                                            <br><br><b>Job Description:</b>
            
                                                            <ul class="side-menu" style= "list-style-type: disc;">
            
                                                                <li>
                                                                   Connect & engage with the new users from the database via a dialer based calling process.
                                                                </li>
                                                                <li>
                                                                    Conduct online product demonstration to showcase, promote and sell subscriptions of the portal.
                                                                </li>
                                                                <li>
                                                                    Achieve sales targets by counseling the users on product features along with related services.
                                                                </li>
                                                                <li>
                                                                    Sell membership plans over the phone and update all the details accurately in the system software.
                                                                </li>
                                                                <li>
                                                                    Maintain call quality standards as per defined matrices.
                                                                </li>
                                                                <li>
                                                                    Develop constructive working relationships with a focus on users to achieve conversion to higher valued plans.
                                                                </li>
                                                           
                                                            </ul>
                                                            <b>Required Skills:</b>
                                                            <ul class="side-menu" style= "list-style-type: disc;">
            
                                                                <li>
                                                                    <span class="side-menu__label">Good communication skills</span>
                                                                </li>
                                                                <li>
                                                                    <span class="side-menu__label">Active listening skills</span>
                                                                </li>
                                                                <li>
                                                                    <span class="side-menu__label">Strong convincing skills</span>
                                                                </li>
                                                                <li>
                                                                    <span class="side-menu__label">Client handling skills</span>
                                                                </li>
            
                                                            </ul>
                                                            <b>Experience:</b>
                                                            <ul class="side-menu" style= "list-style-type: disc;">
            
                                                                <li>
                                                                    <span class="side-menu__label">1-3 Years</span>
                                                                </li>
                                                                <li>
                                                                    <span class="side-menu__label">Any Diploma/Graduate</span>
                                                                </li>                                                    
            
                                                            </ul>
                                                            <b>Desired Skills:</b>
                                                            <ul class="side-menu" style= "list-style-type: disc;">
            
                                                                <li>
                                                                    Prior experience in inside sales/business development/voice process will be preferred
                                                                </li>
                                                                <li>
                                                                   Solution-oriented with effective problem-solving skills
                                                                </li>
                                                                <li>
                                                                   Knowledge of various online portals will be an added advantage
                                                                </li>
                                                                <li>
                                                                    Digital marketing experience will be an added advantage
                                                                </li>                                                    
            
                                                            </ul>
                                                            <b>Employment Type:</b> Full-time
            
                                                            <br><br><b>Education:</b> Any Graduate
            
                                                            <br><br><b><a href="mailto:jobs@getyourvendor.com">Apply Now</a></b>
            
                                                        </div>
                                                        </div>
                                                    
                                                    </div>
                                                    
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                           
                                        </div>
                                                    <div class="panel-group1" id="accordion3">
                                                        <div class="panel panel-default mb-4 border">
                                                            <div class="panel-heading1 vendor-profile-panel">
                                                                <h4 class="panel-title1">
                                                                    <a class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion3" href="#collapsevendor" aria-expanded="false">
                                                                        Sourcing Engineer/ Vendor Development Engineer: 2-5 Years , Permanent Position, Location: Pune</a>
                                                                </h4>
                                                            </div>
                                                            <div id="collapsevendor" class="panel-collapse collapse" role="tabpanel" aria-expanded="false">
                                                                <div class="panel-body card-bg">
                                                                
                                                                <div class="row">
                                                                <div class="table-responsive">
                                                                    <div class="tag-search" style="margin: 2%;"><b> Responsible for:</b> 
                        
                                                                        <ul class="side-menu" style= "list-style-type: disc;">
                        
                                                                            <li>
                                                                                Understanding RFQ drawings published by Buyers
                                                                            </li>
                                                                            <li>
                                                                                Contacting vendors/buyers from existing database for RFQ/Quotations
                                                                            </li>
                                                                            <li>
                                                                                Coordinating between Sales/Marketing/Development departments 
                                                                            </li>
                                                                            <li>
                                                                                Visiting vendors/suppliers as per situation demands
                                                                            </li>
                                                                            <li>
                                                                                Ensuring maximum buyers are registering on the portal
                                                                            </li>
                                                                            <li>
                                                                                Ensuring paid subscriptions from the vendors by providing them RFQ support.
                                                                            </li>
                                                                            <li>
                                                                                Keeping track of incoming RFQ’s and maintain accurate data for the same in the system
                                                                            </li>
                                                                            <li>
                                                                                Maintaining professional communication with all vendors and buyers
                                                                            </li>
                                                                            <li>
                                                                                Conduct virtual vendor assessment and maintain confidentiality  
                                                                            </li>
                                                                    
                                                                        </ul>
                                                                        <b>Required Skills:</b>
                                                                        <ul class="side-menu" style= "list-style-type: disc;">
                        
                                                                            <li>
                                                                                <span class="side-menu__label">Good communication skills</span>
                                                                            </li>
                                                                            <li>
                                                                                <span class="side-menu__label">Active listening skills</span>
                                                                            </li>
                                                                            <li>
                                                                                <span class="side-menu__label">Strong convincing skills</span>
                                                                            </li>
                                                                            <li>
                                                                                <span class="side-menu__label">Client handling skills</span>
                                                                            </li>
                        
                                                                        </ul>
                                                                        <b>Experience:</b>
                                                                        <ul class="side-menu" style= "list-style-type: disc;">
                        
                                                                            <li>
                                                                                <span class="side-menu__label">2-5 Years</span>
                                                                            </li>
                                                                            <li>
                                                                                <span class="side-menu__label">Any Diploma/Graduate</span>
                                                                            </li>                                                    
                        
                                                                        </ul>
                                                                        <b>Desired Skills:</b>
                                                                        <ul class="side-menu" style= "list-style-type: disc;">
                        
                                                                            <li>
                                                                                Prior experience in vendor development department will be preferred
                                                                            </li>
                                                                            <li>
                                                                            Solution-oriented with effective problem-solving skills
                                                                            </li>
                                                                            <li>
                                                                            Knowledge of various online portals will be an added advantage
                                                                            </li>
                                                                            <li>
                                                                                Digital marketing experience will be an added advantage
                                                                            </li>                                                    
                        
                                                                        </ul>
                                                                        <b>Employment Type:</b> Full-time
                        
                                                                        <br><br><b>Education:</b> Any Graduate
                        
                                                                        <br><br>Send Your Details at - <b>jobs@getyourvendor.com</b>
                        
                                                                    </div>
                                                                    </div>
                                                                
                                                                </div>
                                                                
                                                                
                                                                </div>
                                                            </div>
                                                        </div>
                                                    
                                                    </div>
                                                    </div>  
                                                </div>

                                            </div>	
										

										
									    </div>
										
									</div>

								</ng-container>
								
								<div class="card-body">We have multiple open positions in marketing, web development do send your cv at jobs@getyourvendor.com.</div>

								</div>

							<!-- </div> -->
						</div>
						<!--/Itemsd lists-->
					</div>
                    <div class="col-lg-12 col-sm-12 col-md-12">
                        <div class="mb-0" >
                            <div class="card-header">
                                <h2> Why Join Us!!</h2>
                            </div>
                           <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6"  >
                              
                                <p>This is a unique opportunity to work on innovative and disruptive technology driven business solutions
                                    that are shaping the future of the manufacturing industry. </p><p>Benefits Joining ISSPL team:</p><p>1. Flexible Working Hours</p> 
                                    <p>2. Great Culture </p> <p>3. Fast growth in career.</p><p>4. Hybrid Working</p><p>5. Flexible Vacation Plan</p> <p>6. Premium office location </p> <p>7. Technology Company </p><p>8. Equal opportunity employer </p> 
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <img src="assets/images/media/team.webp" height="100%" width="100%">
                            </div>
                        </div>
                    </div>
                    </div>			
				</div>
			</div>
		</section>
		




  
<!-- /Conatct -->


<login-home-footer></login-home-footer>