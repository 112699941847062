
    <!-- Mobile Header -->
    <div id="sticky-wrapper" class="sticky-wrapper" style="height: 31.5833px;">
        <div class="horizontal-header bg-dark-transparent clearfix " >
            <div class="container">
                <a id="horizontal-navtoggle" (click)="happy()" class="animated-arrow"><span></span></a>
                <span class="smllogo"><img src="assets/images/brand/logo-dark.webp" alt="gyv" width="50%" height = "100%" loading="lazy"></span>
                <span class="smllogo-white"><img src="assets/images/brand/logo.webp" alt="gyv" width="50%" height = "100%"  loading="lazy"></span>
                
                <a routerLink="/login" class="callusbtn"><i class="fa fa-user" aria-hidden="true"></i></a>
            </div>
        </div>
    </div>

 

    <!--Horizontal-main -->
    <div id="sticky-wrapper" class="sticky-wrapper" id="t-navbar">
        <div class="horizontal-main header-style1  bg-dark-transparent clearfix">
            <div class="horizontal-mainwrapper container clearfix">
                <div class="desktoplogo">
                    <a href="/">
                        <img src="assets/images/brand/logo1.png" width="50%" height="50%" alt="gyv" class="header-brand-img1" loading="lazy">
                        <img src="assets/images/brand/logo.webp" width="50%" height="50%" class="header-brand-img header-white" alt="gyvlogo" loading="lazy">
                    </a>
                </div>
                <div class="desktoplogo-1">
                    <a href="/">
                        <img src="assets/images/brand/logo1.png" width="50%" height="50%" alt="gyv" class="header-brand-img1" loading="lazy">
                        <img src="assets/images/brand/logo.webp" width="50%" height="50%" class="header-brand-img header-white" alt="logo" loading="lazy">
                    </a>
                    
                </div>
               
                <nav class="horizontalMenu clearfix d-md-flex">
                    <div class="horizontal-overlapbg"></div>
                    <ul class="horizontalMenu-list">
                        <li aria-haspopup="true"><a [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }" routerLink="/" >Home </a>

                        </li>
                        <li aria-haspopup="true"><span class="horizontalMenu-click horizontal-activearrow"><i class="horizontalMenu-arrow fa fa-angle-down"></i></span><a style="cursor: pointer;">For Buyers <span class="fe fe-chevron-down m-0"></span></a>
                            <ul class="sub-menu" style="display: block;">
                                 <li><a [routerLinkActive]="'active'" routerLink="/buyers-benefits">Buyers Benefits</a></li>
                                 <li><a  [routerLinkActive]="'active'" routerLink="/login">Request a Custom Part Quote</a></li> 
                                 <li><a  [routerLinkActive]="'active'" routerLink="/testimonials">Testimonials</a></li> 
                                 <li><a  [routerLinkActive]="'active'" routerLink="/customerwins">Success Stories</a></li> 
                            </ul>
                        <li aria-haspopup="true"><span class="horizontalMenu-click horizontal-activearrow"><i class="horizontalMenu-arrow fa fa-angle-down"></i></span><a style="cursor: pointer;">For Vendors<span class="fe fe-chevron-down m-0"></span></a>
                            <ul class="sub-menu" style="display: block;">
                                <li><a [routerLinkActive]="'active'" routerLink="/vendors-benefits">Vendors Benefits</a></li>
                                <li><a [routerLinkActive]="'active'" routerLink="/login">Quote Custom Part</a></li>
                               
                                <li><a  [routerLinkActive]="'active'" routerLink="/testimonials">Testimonials</a></li> 
                                <li><a  [routerLinkActive]="'active'" routerLink="/customerwins">Success Stories</a></li> 
                            </ul>
                      
                         <li aria-haspopup="true"><span class="horizontalMenu-click horizontal-activearrow"><i class="horizontalMenu-arrow fa fa-angle-down"></i></span><a style="cursor: pointer;">Company<span class="fe fe-chevron-down m-0"></span></a> 
                            <ul class="sub-menu" style="display: block;">
                                <li><a [routerLinkActive]="'active'" routerLink="/aboutus" >About Us</a></li>
                              
                                <li><a [routerLinkActive]="'active'" routerLink="/blog">Blogs</a></li>
                                <li><a [routerLinkActive]="'active'" routerLink="/career">Careers</a></li>
                                <li><a  [routerLinkActive]="'active'" routerLink="/testimonials">Testimonials</a></li> 
                                <li><a [routerLinkActive]="'active'" routerLink="/contactus">Contact Us</a></li>
                                <li><a [routerLinkActive]="'active'" routerLink="/strategicpartner">Partner Program</a></li>
                                <li><a [routerLinkActive]="'active'" routerLink="/newpricingpage">Pricing </a></li>
                                <li><a [routerLinkActive]="'active'" routerLink="/faq">FAQ's</a></li>
                               
                            </ul>
                      
                       
                      
                        <li aria-haspopup="true"><span class="horizontalMenu-click horizontal-activearrow"><i class="horizontalMenu-arrow fa fa-angle-down"></i></span><a style="cursor: pointer;">Login<span class="fe fe-chevron-down m-0"></span></a>
                        <ul class="sub-menu" style="display: block;">
                            <li><a [routerLinkActive]="'active'" routerLink="/login" title="Already User">Login</a></li>
                            <li><a [routerLinkActive]="'active'" routerLink="/login/signup" title="New to GetYourVendor" >Sign Up</a></li>
                           
                        </ul>

                     
                            <li class="tiral"><a [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }" routerLink="/login/signup" >FREE TRIAL </a>
    
                        

                    </ul>
                    
                    

                </nav>
              
                
            </div>
        </div>
        
    </div>
  