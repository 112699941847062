import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AlertService } from '../service/alert.service';
import { AuthenticationService } from '../service/authentication.service';


@Component({
  selector: 'login-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  

  
  constructor(public toastr: ToastrManager,
    private route: ActivatedRoute, private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,) 
    {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;


     }

     
  
  ngOnInit(): void {



  }

  
}
