
<login-home-navigation></login-home-navigation>

<section class="sptb" style="background-color: #fff;">

  <div class="container">
    
      
   
    <div class="row">
      <div class="col-lg-12 col-md-12  col-sm-12" style="text-align: justify;margin-top: 2%;"><h1>Congratulations on taking a step towards success by scheduling a live demo! Not only will you receive a free RFQ and reward points, but you'll also benefit from a tailored demo experience by attaching your company profile. 
        Don't miss out on this opportunity to get the most out of your demo. We're looking forward to helping you achieve your goals!.</h1>    </div>
     </div>
    <div class="row">
      <div class="col-sm-6 col-lg-6 col-md-6">
       <img src="assets/images/media/demo.webp">
      </div>
      <div class="col-sm-6 col-lg-6 col-md-6 ">
        <mat-card>  
          <mat-card-content>
      
              
          <div *ngIf="show_step == 'one'">
            <form [formGroup]="firstFormGroup" (ngSubmit)="firststep"> 
                
                <div>
                  <div class="input-box">
                    <label class="control-label form-label" style="margin-left: 1rem; margin-right: 1rem;">Company Name <span style="color: red;">*</span></label>
                  </div>
                  <div class="row" style="margin-left: 1rem; margin-right: 1rem;" >                                  
                    <input type="text" formControlName="company_name" class="form-control" [ngClass]="{ 'is-invalid': next && f.company_name.errors }" />
                      <div style="text-align: left;" *ngIf="next && f.company_name.errors" class="invalid-feedback">
                        <div *ngIf="f.company_name.errors.required">Company Name is required</div>
                        <div *ngIf="f.company_name.errors.pattern">Enter Valid Company Name </div>
                      </div>
                  </div>
                </div>
                
                <div>
                 <div class="input-box">
                  <label class="control-label form-label"style="margin-left: 1rem; margin-right: 1rem;" >Contact Person<span style="color: red;">*</span></label>
                 </div>
                 <div class="row" style="margin-left: 1rem; margin-right: 1rem;" >
                   <input type="text" formControlName="contact_person" class="form-control" [ngClass]="{ 'is-invalid': next && f.contact_person.errors }" />
                    <div style="text-align: left;" *ngIf="next && f.contact_person.errors" class="invalid-feedback">
                      <div *ngIf="f.contact_person.errors.required">Contact Person Name is required</div>
                      <div *ngIf="f.contact_person.errors.pattern">Enter Valid Contact Person Name </div>
                    </div>
                </div>
                </div>
            
                <div>
                 <div class="input-box">
                  <label class="control-label form-label"style="margin-left: 1rem; margin-right: 1rem;" >Phone<span style="color: red;">*</span></label>
                 </div>
                <div class="row"style="margin-left: 1rem; margin-right: 1rem;" >                                  
                  <input type="number" formControlName="phone" onKeyPress="if(this.value.length==15) return false;" class="form-control" [ngClass]="{ 'is-invalid': next && f.phone.errors }" />
                    <div style="text-align: left;" *ngIf="next && f.phone.errors" class="invalid-feedback">
                      <div *ngIf="f.phone.errors.required">Phone is required</div>
                      <div *ngIf="f.phone.errors.pattern">Minimum 10 digits are required</div>
                    </div>
                </div>
                </div>
      
                <div>
                <div class="input-box">
                  <label class="control-label form-label"style="margin-left: 1rem; margin-right: 1rem;" >Email Id<span style="color: red;">*</span></label>
                </div>
               <div class="row"style="margin-left: 1rem; margin-right: 1rem;" >
                <input type="text" formControlName="email" class="form-control" type="email" [ngClass]="{ 'is-invalid': next && f.email.errors }" />
                 <div style="text-align: left;" *ngIf="next && f.email.errors" class="invalid-feedback">
                   <div *ngIf="f.email.errors.required">Email Id is required</div>
                   <div *ngIf="f.email.errors.pattern">Enter Valid Email Id</div>
                 </div>
              </div>
              </div>
      
                <div>
                  <div class="row" style="padding-top: 2rem; margin-left:1rem;">      
                  <label class="control-label form-label" >Do you have a company introduction file ?<span style=" color: red;">*</span></label>
                  <div style="margin-left:0.45rem;">
                  <label class="custom-control custom-radio pull-left"> 
                        <input type="radio" class="custom-control-input" (change)="checkradio($event)"  formControlName="profile" value="yes" checked [ngClass]="{ 'is-invalid': next && f.profile.errors }">
                        <span class="custom-control-label"><b>Yes</b></span>
                  </label>
                  </div>
                  <div style="margin-left:0.45rem;">
                  <label class="custom-control custom-radio pull-right">
                        <input type="radio" class="custom-control-input" (change)="checkradio($event)"  formControlName="profile" value="no" checked [ngClass]="{ 'is-invalid': next && f.profile.errors }">
                        <span class="custom-control-label"><b>No</b></span>
                  </label></div>
                  </div>
                </div>
      
              <div class="row"style="padding-top: 2rem; margin-left: 1rem; margin-right: 1rem;">
                <div  *ngIf="f.profile.value === 'yes'">
                  <label class="control-label form-label" style="margin-right: 1rem;">Upload Company Profile:</label>
                  <button class="file-upload-button1">
                    <input type="file" #fileInput style="width: 100%; height: 100%;" (change)="onFileChange($event)"/>          
                  </button>
                  <span>{{filename}}
                    <div style="margin-left:0.1rem;" class="row">
                      (File size limit 3MB)
                    </div>
                  </span>
                </div>
              </div>
      
      
                <div class="example-button-row pull-right" >
                  <button mat-raised-button (click)="firststep()" class="nextbtn ml-7">Next</button>
                </div>
                
              </form>
              </div>
      
              <div *ngIf="show_step == 'two'">
                <form [formGroup]="secondFormGroup" (ngSubmit)="onSubmit">
                  
          
                  <div class="input-box">
                    <label>Password<span style="color: red;">*</span></label>
                    <div class="row col-md-11">
                      <button (click)="onClick()"  class= "button1" type="button" tabindex = "-1">
                        <i class="fa fa-eye" aria-hidden="true" *ngIf="show"></i>
                        <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="!show"></i>
                      </button>
                    </div>
                  </div> 
                  <div>                 
                    <input [type]="password" formControlName="password" class="form-control" size="8" maxlength="16" [ngClass]="{ 'is-invalid': step2 && f2.password.errors }"/>
                      <div style="text-align: left;" *ngIf="step2 && f2.password.errors" class="invalid-feedback">
                        <div *ngIf="f2.password.errors.required">password is required</div>
                        <div *ngIf="f2.password.errors.minlength">Password must be at least 8 characters</div>
                      </div>
                  </div>
      
                  <div class="input-box">
                    <label>Confirm Password<span style="color: red;">*</span></label>
                    <div class="row col-md-11">
                      <button (click)="onClick1()" class= "button1" type="button" tabindex = "-1">
                        <i class="fa fa-eye" aria-hidden="true" *ngIf="show1"></i>
                        <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="!show1"></i>
                      </button>
                    </div>
                  </div>
                  <div>
                    <input [type]="password1" formControlName="confirm_password" class="form-control" size="8" maxlength="16" [ngClass]="{ 'is-invalid': step2 && f2.confirm_password.errors }"/>
                      <div style="text-align: left;" *ngIf="step2 && f2.confirm_password.errors" class="invalid-feedback">
                        <div *ngIf="f2.confirm_password.errors.required">Confirm Password is required</div>
                        <div *ngIf="f2.confirm_password.errors.mustMatch">Passwords must match</div>
                      </div>
                  </div>
      
                  <div class="row col-sm-12 col-md-12 col-lg-12" style="padding-top: 2rem; margin-left:1rem; text-align: justify;">
                    <div class="g-recaptcha" data-theme="light">
                      <input type="text" class="form-control" formControlName="mycaptcha1" hidden required >        
                      <re-captcha (resolved)="resolved($event)"  
                        siteKey="6Ld9YPMZAAAAALZ7si32freu_SYFZe8UaZ7Z5NdN">  
                      </re-captcha>
                      <div *ngIf="mycaptcha==false" style="text-align: left;" class="custom-control custom-checkbox invalid-feedback">
                        Please Enter Captcha
                      </div>
                    </div>
                  </div>
      
                  <div class="row col-sm-12 col-md-12 col-lg-12" style="padding-top:1rem; text-align:justify;">
                      <label class="custom-control custom-checkbox">
                          <input (change)="listener($event)" type="checkbox" formControlName="agreeTerms" class="custom-control-input" />
                          <span *ngIf="f2.agreeTerms.value == true" class="custom-control-label text-dark text-wrap ml-2">Yes, I have read and agree with the  <a routerLink="/terms" target="_blank" style="color:#14baf1;">Terms of Service</a> & <a routerLink="/privacy-policy" target="_blank" style="color:#14baf1;">Privacy Policy</a></span>
                          <span *ngIf="f2.agreeTerms.value == false" class="custom-control-label text-red text-wrap ml-2">Yes, I have read and agree with the  <a routerLink="/terms" target="_blank" style="color:#14baf1;">Terms of Service</a> & <a routerLink="/privacy-policy" target="_blank" style="color:#14baf1;">Privacy Policy</a></span>                                  
                      </label>
                  </div>
                  
                  <div class="container row" style="padding-top:1rem;">
                    <div class="col-sm-6 col-md-6 col-lg-10">
                    <div class="example-button-row">
                      <button mat-raised-button class="nextbtn" (click)="prev()">Previous</button>
                    </div>
                    </div>
                   
                    <div class="col-sm-6 col-md-6 col-lg-2">
                    <div class="example-button-row">
                      <button mat-raised-button class="nextbtn" [disabled]="disable" (click)="onSubmit()">Submit</button>
                    </div>
                    </div>
                  </div>
             </form>
            </div>
      
      </mat-card-content>
      </mat-card>
      </div>
    </div>
  </div>
      

</section>



<login-home-footer></login-home-footer>

